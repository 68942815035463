import React from 'react';
import { InoveLink } from './inove.style';

const Logo = () => (
  <InoveLink
    href="https://inovesistemas.info"
    target="_blank"
    rel="noopener noreferrer"
    title="Desenvolvido pela Inove Sistemas"
  />
);

export default Logo;
