import React, { Component } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import SpaceCol from '../space-col';
import Title from '../title';
import Result from '../result';

class OthersResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  viewImage(index) {
    this.setState({ photoIndex: index, isOpen: true });
  }

  render() {
    const { images } = this.props;
    const { photoIndex, isOpen } = this.state;

    return (
      <section>
        <Row>
          <Col xs={12}>
            <Title center>Outros resultados</Title>
          </Col>
        </Row>
        <Row>
          {images.map((img, index) => (
            <SpaceCol key={img.id} xs={6} sm={4} md={2}>
              <Result onClick={() => this.viewImage(index)} thumb={img.thumb} />
            </SpaceCol>
          ))}
        </Row>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].original}
            nextSrc={images[(photoIndex + 1) % images.length].original}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].original
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </section>
    );
  }
}

export default OthersResults;
