import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from './theme';
import Root from '../screens/root';

const GlobalStyle = createGlobalStyle`
  img {
    max-width: 100%;
  }
  a {
    cursor: pointer;
  }
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Root />
    </>
  </ThemeProvider>
);

export default App;
