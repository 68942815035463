import style from 'styled-components';

export const VideoWrapper = style.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    padding-top: 40%;
  }
`;

export const Iframe = style.iframe`
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 0;
  background: #000;
`;

export const Lottery = style.a`
  display: block;
  margin: 10px;
  padding: 20px 30px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.color.inverse};
  background: ${({ theme }) => theme.color.primary};
  transition: all 200ms;
  border-radius: 4px;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.inverse};
  }

  > span {
    display: block;
  }
`;
