import style from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';

export const Footer = style.footer`
  width: 100%;
  padding: 30px;
  background: #ddd;
`;

export const CenterCol = style(Col)`
  text-align: center;
`;

export const EndCol = style(Col)`
  text-align: right;
`;
