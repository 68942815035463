import React from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import { Nav } from './navbar.style';
import Logo from '../logo';

function Navbar() {
  return (
    <Nav>
      <Grid>
        <Row>
          <Col>
            <Logo />
          </Col>
        </Row>
      </Grid>
    </Nav>
  );
}

export default Navbar;
