import React from 'react';
import { SocialLink } from './social.style';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';

const icons = {
  facebook: <FacebookIcon />,
  youtube: <YoutubeIcon />
};

const Social = props => (
  <SocialLink href={props.url} target="_blank" rel="noopener noreferrer">
    {icons[props.name]}
  </SocialLink>
);

export default Social;
