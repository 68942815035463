import style from 'styled-components';

export const SocialLink = style.a`
  display: inline-block;
  margin: 10px;
  padding: 10px;
  text-align: center;
  background: transparente;
  border-radius: 50%;

  :hover {
    background: ${({ theme }) => theme.color.primary};
  }

  > svg {
    width: 28px;
    height: 28px;
    fill: ${({ theme }) => theme.color.inverse};
  }
`;
