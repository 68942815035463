import React from 'react';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { Footer, CenterCol, EndCol } from './footer.style';
import Inove from '../inove';
import Social from '../social';

export default () => (
  <Footer>
    <Grid>
      <Row style={{ alignItems: 'center' }}>
        <CenterCol smOffset={7} sm={7} xsOffset={1} xs={8}>
          <Social
            name="facebook"
            url="https://facebook.com/serradasorteoficial"
          />
          <Social
            name="youtube"
            url="https://www.youtube.com/channel/UCM_UgWoM4WDReRFwh5AgM9w"
          />
        </CenterCol>
        <EndCol sm={2} xs={3} align="right">
          <Inove />
        </EndCol>
      </Row>
    </Grid>
  </Footer>
);
