import React, { Component } from 'react';
import { Grid } from 'react-styled-flexboxgrid';
import Main from '../../components/main';
import Player from '../../components/player';
import Banner from '../../components/banner';
import LastDrawn from '../../components/last-drawn';
import OthersResults from '../../components/others-results';
import { getYoutubeVideos, getData } from '../../api';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: '',
      last_drawn: [],
      videos: [],
      others_results: []
    };
  }

  componentDidMount() {
    getYoutubeVideos()
      .then(({ data }) => {
        this.setState({ videos: data.items });
      })
      .catch(e => {
        console.error(e);
        alert('Falha ao carregar os dados!');
      });

    getData()
      .then(res => this.setState(res.data))
      .catch(e => {
        console.error(e);
        alert('Falha ao carregar os dados!');
      });
  }

  render() {
    const playerUrl = [
      'https://www.youtube.com/embed/live_stream',
      '?channel=UCM_UgWoM4WDReRFwh5AgM9w',
      '&amp;autoplay=1',
      '&amp;showinfo=0',
      '&amp;controls=0'
    ].join('');

    return (
      <>
        <section>
          <Player url={playerUrl} others={this.state.videos} />
        </section>
        <Main>
          <Grid>
            <LastDrawn peoples={this.state.last_drawn} />
            <Banner image={this.state.banner} />
            <OthersResults images={this.state.others_results} />
          </Grid>
        </Main>
      </>
    );
  }
}

export default Home;
