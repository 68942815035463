import style from 'styled-components';

export const Wrapper = style.div`
  text-align: center;
  margin: 5px;
`;

export const Image = style.img`
  border-radius: 4px;
`;

export const Name = style.h3`
  font-weight: normal;
  font-size: 18px;
`;
