import style, { css } from 'styled-components';

export const Title = style.h1`
  font-size: 32px;
  font-weight: normal;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
`;
