import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import SpaceCol from '../space-col';
import Title from '../title';
import Drawn from '../drawn';

const LastDrawn = props => (
  <section>
    <Row>
      <Col xs={12}>
        <Title center>Últimos premiados</Title>
      </Col>
    </Row>
    <Row>
      {props.peoples.map(people => (
        <SpaceCol key={people.id} xs={12} sm={4} md={3}>
          <Drawn image={people.image} name={people.name} />
        </SpaceCol>
      ))}
    </Row>
  </section>
);

export default LastDrawn;
