import style from 'styled-components';
import Logo from './logo.svg';

export const InoveLink = style.a`
  display: inline-block;
  width: 26.315px;
  height: 25px;
  margin: 15px 0;
  background: url(${Logo});
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;
