import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { VideoWrapper, Iframe, Lottery } from './player.style';

const splitTitle = title => {
  const matches = title.match(/^([\w\s]+)\s(\d{2}\/\d{2}\/\d{4})$/);
  return matches ? (
    <>
      <span>{matches[1]}</span>
      <span>{matches[2]}</span>
    </>
  ) : (
    <span>{title}</span>
  );
};

const Player = ({ url, others }) => (
  <>
    <VideoWrapper>
      <Iframe
        title="Vídeo"
        src={url}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </VideoWrapper>
    <Grid>
      <Row center="xs">
        {others.map(
          item =>
            item.id.videoId ? (
              <Col key={item.id.videoId}>
                <Lottery
                  href={`https://youtu.be/${item.id.videoId}`}
                  target="_blank"
                >
                  {splitTitle(item.snippet.title)}
                </Lottery>
              </Col>
            ) : (
              ''
            )
        )}
      </Row>
    </Grid>
  </>
);

export default Player;
