import axios from 'axios';

const key = 'AIzaSyBumY2yw3NEQXCngYky35Qyq1XiJXo_zzE';
const channelId = 'UCM_UgWoM4WDReRFwh5AgM9w';

export const getYoutubeVideos = () => {
  const url = [
    'https://www.googleapis.com/youtube/v3/search',
    `?key=${key}`,
    `&channelId=${channelId}`,
    '&part=snippet,id',
    '&order=date',
    '&maxResults=20'
  ].join('');

  return axios.get(url);
};

export const getData = () => axios.get('//api.serradasorte.com.br');
