import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Image } from './banner.style';

const Banner = props => (
  <section>
    <Row>
      <Col xs={12}>
        <Image src={props.image} />
      </Col>
    </Row>
  </section>
);

export default Banner;
