import React from 'react';
import { Wrapper, Image, Name } from './drawn.style';

const Drawn = props => (
  <Wrapper>
    <Image src={props.image} />
    <Name>{props.name}</Name>
  </Wrapper>
);

export default Drawn;
