import React from 'react';
import { Wrapper } from './result.style';

const Result = props => (
  <Wrapper onClick={props.onClick}>
    <img src={props.thumb} alt="Resultado" />
  </Wrapper>
);

export default Result;
