import style from 'styled-components';

export const Wrapper = style.div`
  padding: 15px;
  margin: 10px;
  cursor: pointer;
  border: #ccc 2px solid;
  background: #fff;
  border-radius: 4px;
  transition: all 200ms;

  :hover {
    border-color: ${({ theme }) => theme.color.primary}
    transform: scale(1.1);
  }
`;
